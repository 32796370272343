<template>
  <div>
    <img
      v-if="data.productThumbnailImageUrl"
      class="image"
      :src="data.productThumbnailImageUrl"
      alt=""
    />
    <img
      v-if="!data.productThumbnailImageUrl"
      class="image"
      src="https://asset.fsytss.com/trace/wcj/index_default.png"
      alt=""
    />

        <!-- 批次信息 -->
        <div class="batchContainer">
      <div class="batchTitle">批次信息</div>
      <div class="batchInfo">
        <div class="bacthKey">产品批次</div>
        <input class="batchValue"   v-model="batchValue"  placeholder="请从产品外包装喷码处查询"  @keyup.enter="search">
        <div class="batchValue-btn" @click="search()"> 查询 </div>
      </div>
    </div>

    <div class="dateImgClass"  v-if="data.batchData&&data.batchData.status==0">
      <!-- <img src="../../assets/images/project1/indexdate.png" alt=""> -->
      <div>
        <div class="dateText">养殖</div>
        <div class="dateText" style="margin-top: 4px">天数:</div>
      </div>
      <div style="font-size: 33px; line-height: 33px">{{ data.cycleDay }}</div>
      <div style="font-size: 14px; line-height: 14px; margin-top: 12px">天</div>
    </div>
    <div class="progress">
      <img
        style="width: 80%"
        src="../../assets/images/project1/chickenProgress.png"
        alt=""
      />
      <img
        style="
          width: 63px;
          height: 67px;
          position: absolute;
          top: -30px;
          right: -10px;
          transform: scaleX(-1);
        "
        src="../../assets/images/project1/chickenGif.gif"
        alt=""
      />
    </div>
    <!-- 基本信息 -->
    <div class="baseInfo">
      <div class="infoContainer"  v-if="data.batchData&&data.batchData.status==0">
        <div class="info_bottom">
          <img
            src="../../assets/images/project1/tick.png"
            style="width: 41px; height: 41px; margin-top: 12px"
          />
          <div class="info_bottomLeft">

            <p class="title">真品验证通过！</p>
            <!-- <label class="positioned">查询次数: {{ data.scanNumber }}次 </label> -->
            <!-- <label class="positioned positioned-error" v-if="data.exceedScanNumber && data.exceedScanNumber==1">查询次数过多 ，请核实</label> -->
            <!-- <p class="positioned">溯 源 I D: {{ data.tracingNo }}</p> -->
            <p class="positioned">批次编号:  {{ data.batchData.data.breedingBatchCode }}</p>
            <p class="positioned positioned-error" v-if="data.exceedPopFarmDays && data.exceedPopFarmDays==1">距离出栏时间较久，请核实</p>
          </div>
        </div>
      </div>
      <div class="infotag">基本信息</div>

      <div class="infoItem">
        <div class="infodetail">
          <div class="key">产品名称</div>
          <div class="value">{{ data.productName }}</div>
        </div>
        <div class="line">
       
        </div>
      </div>
      <div class="infoItem" v-if="data.fattenPushPopTime">
        <div class="infodetail">
          <div class="key">出栏日期</div>
          <div class="value">{{ data.formatFattenPushPopTime  }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="infoItem">
        <div class="infodetail">
          <div class="key">出品企业</div>
          <div class="value">{{ data.supplierName }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="infoItem" v-if="data.batchData&&data.batchData.status==0">
        <div class="infodetail">
          <div class="key">商品规格</div>
          <div class="value">{{ data.batchData.data.productSpec.spec }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="infoItem">
        <div class="infodetail">
          <div class="key">产地</div>
          <div class="value">{{ data.sourceAddress }}</div>
        </div>
        <div class="line"></div>
      </div>
    </div>

    


    <!-- 产地定位 -->
    <div class="baseinfo" style="margin-top: 26px" v-if="latitude">
      <div class="addressTag">产地定位</div>
      <div class="placeAddress">
        <div class="address" id="container"></div>
        <div class="addressInfo">地址：{{ data.sourceAddress }}</div>
        
      </div>
    </div>
    <!-- 检测报告 -->
    <div
      class="baseinfo"
      style="margin-top: 26px"
      v-if="
        data.manufactureQualityInspectFile ||
        data.manufactureAnimalQuarantineFile 
      "
    >
      <div class="addressTag">检测报告</div>
      <div class="placeAddress">
        <!-- 轮播 -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-if="data.manufactureQualityInspectFile"
            ><img
              class="bannerImg"
              :src="data.manufactureQualityInspectFile"
              alt=""
              @click="reportClick('manufactureQualityInspectFile')"
          /></van-swipe-item>
          <van-swipe-item v-if="data.manufactureAnimalQuarantineFile"
            ><img
              class="bannerImg"
              :src="data.manufactureAnimalQuarantineFile"
              alt=""
              @click="reportClick('manufactureAnimalQuarantineFile')"
          /></van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- 区块链信息 -->
    <div class="baseinfo" style="margin-top: 26px" v-if="data.formatFattenPushPopTimeTime">
      <div class="addressTag">区块链信息</div>


      <div class="area" id="areaCanvasImg">
        <div class="clickDownLaod" @click="imagePreview(url)">点击放大 ></div>
        <div class="areaContainer" >
          <div class="areaChidBg" ref="canvasImg">
            <img   src="../../assets/images/project1/areaBlueOrder.png" alt=""  />
            <div   class="img" >  
              <div class="firstLine">海南省文昌市畜牧兽医服务中心</div>
              <div class="secondLine">{{ data.manufactureOrderContractId }}</div>
              <div class="thirdLine">{{ data.formatFattenPushPopTimeTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="area" style="background: #fff; padding: 0 9px">
        <div
          style="
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
            margin-right: 7px;
          "
        >
          <div
            style="color: #b44130; font-size: 13px"
            @click="imagePreview(url)"
          >
            点击放大 >
          </div>
        </div>
        <div class="areaimg" ref="canvasImg"  >
          <div class="areaimgText" >
            <div>海南省文昌市畜牧兽医服务中心</div>
            <div class="only">{{ data.manufactureOrderContractId }}</div>
            <div>{{ data.formatFattenPushPopTimeTime }}</div>

          </div>
        </div>
      </div> -->
    </div>

    <!-- 底部技术支持 -->
    <div class="bottom">
      <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support.png" alt="">
    </div>
     <!-- 加载loading -->
     <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { ImagePreview ,Loading} from "vant";
import html2canvas from "html2canvas";
import moment from "moment";
import { post } from "../../utils/http";

import { getDiffDay } from "../../utils/util";
Vue.use(ImagePreview).use(Loading);
export default {
  name: "HomeProductComponent",
  props: [
    "data",
    "latitude",
    "longitude",
    "url",
    "productDetailsFigureImageUrl",
    "mobile",
    "traceCode"
  ],
  data() {
    return {
      imgUrl:'',
      loadingAll:false,
      batchValue:'',//输入的批次信息
      batchData:''//请求的批次信息
    };
  },
  mounted() {
    this.initMap();
    this.batchValue =   this.data.batchValue
  },
  methods: {
    search(){ // 批次查询
      this.loadingAll=true
      if(this.batchValue==''){
        this.batchValue=null
       }
       this.data.batchValue =   this.batchValue
      const url =`/api/public/v2/manufacture/tracing/findProductTraceByPopFenceOrderNo`
      const params = {
        businessId: this.data.businessId,//"0193a9551bbd0a15ff808081939edd23",
        productId: this.traceCode,//"0193a95b094805ceff808081939fea3a",
        orderNo: this.batchValue//"2025030300004"
      }
      post(url, params, false).then(res=>{
        // 两个个参数分别代表，是否有阶段数，是否有批次，是否有检测报告
        this.batchData=res
        this.data.batchData = this.batchData
        let cycleDayAll = 0;
        let stageList = res.data.breedingBatchStageList
        if(res.data.fattenPushPopTime){
          this.data.fattenPushPopTime = res.data.fattenPushPopTime
          this.data.formatFattenPushPopTime= moment(this.data.fattenPushPopTime).format(
              "yyyy年MM月DD日"
            )
        }
        stageList.forEach(item=>{
          let breedingBatchStage = item
             
          let startTime = breedingBatchStage.startTime
              ? new Date(
                  moment(breedingBatchStage.startTime).format("yyyy/MM/DD") +
                    " 00:00:00"
                )
              : null;
          let endTime = breedingBatchStage.endTime
              ? new Date(
                  moment(breedingBatchStage.endTime).format("yyyy/MM/DD") +
                    " 23:59:59"
                )
              : null;
          let nowDate = new Date(
            moment(new Date().getTime()).format("yyyy/MM/DD") + " 23:59:59"
          );
          let cycleDay = 0;
          if (startTime && startTime <= nowDate.getTime()) {
            if (endTime && endTime > 0 && endTime <= nowDate.getTime()) {
              cycleDay = getDiffDay(startTime, endTime);
            } else {
              cycleDay = getDiffDay(startTime, nowDate);
            }
          }
          item.cycleDay = cycleDay
          cycleDayAll += cycleDay;
        })
        this.data.cycleDay = cycleDayAll
        this.data.manufactureQualityInspectFile  = res.data.manufactureQualityInspectFile
        this.data.manufactureAnimalQuarantineFile  = res.data.manufactureAnimalQuarantineFile
        console.log(stageList);
        
        this.$emit('getstageList', stageList);
        this.loadingAll=false
      }).catch(err=>{
        this.batchData=err
        this.data.batchData = this.batchData
        this.$emit('getstageList', null,false);
        this.loadingAll=false
        alert('真品验证不通过');
      })
    },
    reportClick(img){
      console.log(img);
      let arr=[]
      if(this.data.manufactureQualityInspectFile){
        arr.push(this.data.manufactureQualityInspectFile)
      }
      if(this.data.manufactureAnimalQuarantineFile){
        arr.push(this.data.manufactureAnimalQuarantineFile)
      }
      let index=0
      if(arr.length==1){
        index=0
      }else if(img=='manufactureAnimalQuarantineFile'){
        index=1
      }
      console.log(index);
      ImagePreview({
        images: arr,
        startPosition: index,
      });

    },
    async createImage() {
      try {
        // const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        // const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
        // this.imgUrl = image;
        // ImagePreview([image]);
        
        
        var canvas2 = document.createElement("canvas");
            let _canvas = this.$refs.canvasImg;
            var w = parseInt(window.getComputedStyle(_canvas).width);
            var h = parseInt(window.getComputedStyle(_canvas).height);
            //将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
            canvas2.width = w * 2;
            canvas2.height = h * 2;
            canvas2.style.width = w + "px";
            canvas2.style.height = h + "px";
            const context = canvas2.getContext("2d");
            context.scale(1,1);
            context.fillStyle = '#FFFFFF'
            context.fillRect(0, 0, canvas2.width, canvas2.height);


            const config = {
            backgroundColor: null,
            canvas: canvas2,
            useCORS: true,//配置允许跨域
            scale: 2,
            dpi: 800 // 处理模糊问题
            };
            console.log("获取指定的宽高", w, h, canvas2);

            const canvas = await html2canvas(this.$refs.canvasImg,config).then(canvas => {
            
            
            try {
                // 转成图片，生成图片地址
                this.imgUrl = canvas.toDataURL("image/png");  
                    ImagePreview([this.imgUrl]);
                } catch (e) {
                alert("图片跨域,保存失败");
                }


            });

      this.loadingAll=false
      } catch (e) {
        this.loadingAll=false
        throw new Error(e);
      }
    },
    imagePreview(url) {
      this.loadingAll=true
      this.createImage();
      
    },
    initMap() {
      if(!this.latitude) return
      let latitude = Number(this.latitude);
      let longitude = Number(this.longitude);
      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.map = new TMap.Map("container", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        styles: {
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.image {
  width: 323px;
  height: 221px;
  margin: 0 auto;
  margin-top: 16px;
  display: block;
  border-radius: 16px;
}
.dateImgClass {
  width: 152px;
  height: 44px;
  margin: 0 auto;
  margin-top: 11px;
  background: url("../../assets/images/project1/indexdate.png");
  background-size: cover;
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding-left: 16px;
  padding-right: 35px;
  align-items: center;
  padding-bottom: 11px;
  padding-top: 9px;
  font-weight: 500;
  // line-height: 33px;
}
.dateText {
  font-size: 12px;
  line-height: 14px;
}
.progress {
  position: relative;
  // border: 1px solid #000;
  width: 230px;
  height: 34px;
  margin: 0 auto;
  margin-top: 24px;
}

.info_bottom {
  display: flex;
  background: url("../../assets/images/project1/passImg.png") no-repeat center /
    cover;
  border-radius: 6px;
  padding: 0px 19px 10px 19px;
  color: #fff;

  .info_bottomLeft {
    width: 250px;
    margin-left: 12px;
    margin-top: 15px;
    overflow: hidden;
    .info_bottomTitle {
      font-size: 15px;
      color: #231815;
      font-weight: bold;
      line-height: 16px;
      margin-bottom: 8px;
    }
    .info_bottomLeftDetail {
      display: flex;
      color: #000;
      font-size: 10px;
      color: #231815;
      line-height: 17px;
    }
    label,p{
      margin: 0px auto;
      font-size: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .title{
      font-size: 13px;
      margin-bottom: 5px;
      font-weight: bold;
    }
    .positioned{
      
      margin-bottom: 3px;
    }
    .positioned-error{

    color: red;
    }

  }
}

.baseInfo {
  background-color: #fff;
  margin: 0 12px;
  margin-top: 16px;
  padding: 0px 0 16px 0;
  position: relative;
  border-radius: 6px;
}

.infotag {
  position: relative;
  width: 95px;
  height: 23px;
  // background: url("../../assets/images/project1/infoTag.png") no-repeat center /
  //   cover;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: 11px;
}
.infoItem {
  width: 100%;
  margin-top: 15px;
  padding: 0 16px;
}
.line {
  height: 0.5px;
  background-color: rgb(139, 202, 157);
  margin-top: 10px;
}
.infodetail {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}
// 产地定位
.addressTag {
  position: relative;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-left: 12px;
}
.placeAddress {
  position: relative;
  background-color: #fff;
  margin: 0 12px;
  // height: 262px;
  margin-top: -11px;
  z-index: 1;
  background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
  overflow: hidden;
  border-radius: 6px;
}
.address {
  width: 287px;
  height: 193px;
  margin: 29px auto 10px auto;
  //margin-top: 29px;
  //   border: 1px solid #000;
}
.addressInfo {
  font-size: 13px;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  margin: 10px auto;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
.areaimg {
  margin: 9px auto;
  width: calc(100vw - 28px * 2 - 9px * 2);
  height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
  // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_blue_order.png") no-repeat
  //   center / cover;
  background: url("../../assets/images/project1/areaBlueOrder.png") no-repeat
    center / cover;
  margin-bottom: 8px;
}

.area {
  
  margin: 0 12px;
  margin-top: -11px;
  z-index: 1;
  overflow: hidden;
  border-radius: 6px;
  background: #fff; 

  .clickRedText{
    color: #d82a1f;
    font-size: 13px;
    // padding: 0 12px;
    text-align: right;
    margin-bottom: 7px;
    
  }
  .clickDownLaod {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    margin-right: 12px;
    color: #b44130; font-size: 13px
  }
  .areaContainer {
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    .areaChidBg{

      position: relative;
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
      margin: auto; 
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
    }
    .img {
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 69px; //18.2
      padding-left: 90px;
      padding-right: 25px;
      font-size: 12px;
      word-break: break-all;
      color: #231815;
      text-align: right;
      line-height: 16px;
      .firstLine{
        width: 200px;
        position: absolute;
        top: 69px;
        left: 80px;
      }
      .secondLine {
        width: 190px;
        position: absolute;
        top: 90px;
        left: 90px;
      }
      .thirdLine{
        width: 200px;
        position: absolute;
        top: 174px;
        left: 80px;
      }
    }
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  width: 287px !important;
  height: 220px !important;
  margin: 0 auto;
  line-height: 150px;
  text-align: center;
  margin-top: 24px;
  .bannerImg {
    max-width: 200px;
        height: 100%;
        max-height: 200px;
        object-fit: contain;
  }
}
.key {
  width: 120px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}
.value {
  font-weight: 400;
  font-size: 13px;
  color: #231815;
  line-height: 20px;
  text-align: right;
  flex: 1;
}

// 批次信息
.batchContainer{
  width: 319px;
  // height: 71px;
  margin: 0 12px;
  background-color: #fff;
  margin-top: 34px;
  // padding: 24px 0 21px 0;
  border-radius: 8px;
  display: inline-block;
  .batchTitle{
    position: relative;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    
    #c04933, #e2bb7d
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: -11px;
  }
  .batchInfo{
    display: flex;
    margin-top: 14px;
    margin-bottom: 21px;
    justify-content: center;
    align-items: center;
    .bacthKey{
        color: #006000;
        font-size: 12px;
        letter-spacing: 1px;
    }
    .batchValue{
        width: 170px;
        height: 26px;
        border: 1px solid #cccccc;
        text-align: center;
        font-size: 12px;
    //     display: flex;
    // justify-content: center;
    // align-items: center;
    margin-left: 8px;
    border-radius: 6px;
    background-color: #f7f7f7;
    }
    .batchValue-btn{
      background-image: linear-gradient(
        135deg,
        
        #c04933, #e2bb7d
      );
      width: 50px;
      height: 26px;
      margin-left: 10px;
      border-radius: 4px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
}
</style>